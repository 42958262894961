<div class="grid grid-rows border-grayLight rounded relative divide divide-y-1 divide-grayLight text-sm">

  <div class="grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span class="font-bold text-lg" i18n>
            Vodenje zaloge vozil
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Dodajanje vozil in pregled zaloge
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Pregled vozila ob nabavi
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Izdaja in hramba dokumentov ob nabavi
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Vnos tehničnih podatkov o vozilu preko VIN številke
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Uvoz zaloge vozil iz Excel predloge
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Kalkulacije in vodenje stroškov
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.CALCULATIONS_AND_COSTS_MANAGEMENT]; else times"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Arhiv dokumentov pri nabavi
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.DOCUMENT_ARCHIVE]; else times"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Informativni izračun davka za uvoz vozila
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.FEATURE_FLAG_DMV_CALCULATION]; else times"></i>
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span class="font-bold text-lg" i18n>
            Blago in Storitve
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <!--        <i class="fas fa-check text-primary" *ngIf="productPackage.featureFlags[AccountFeaturesEnum.ASSETS]; else times"></i>-->
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Samostojen modul za prodajo blaga in storitev.
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.ASSETS]; else times"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span class="font-bold text-lg" i18n>
Oglaševanje vozila
        </span>
      </div>
      <div>
        <span class="text-sm" i18n>

        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Objava vozila v Virtualni salon
        </span>
      </div>
      <div>
        <span class="text-sm" i18n>

        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.ADVERTISEMENT_VIRTUAL_SALON]; else times"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Direktna objava na portale in oglasnike
        </span>
      </div>
      <div>
        <span class="text-sm" i18n>

        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Direktna organska objava na socialnih omrežjih
        </span>
      </div>
      <div>
        <span class="text-sm" i18n>

        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.ADVERTISEMENT_SOCIAL_NETWORKS]; else times"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Tiskanje kartice vozila
        </span>
      </div>
      <div>
        <span class="text-sm" i18n>

        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.PRINT_VEHICLE_CARD]; else times"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Avtomatizirana objava oglasov na socialna omrežja
        </span>
      </div>
      <div>
        <span class="text-sm" i18n>

        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.IS_AVAILABLE_PHYRON]; else times"></i>
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span class="font-bold text-lg" i18n>
            Imenik
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Podatki o kontaktih
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            GDPR obrazec
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Klasifikacije kontaktov
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Opomniki
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.CLIENT_REMINDERS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Beleženje aktivnosti na kontaktih
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.CLIENT_ACTIVITY_TRACKING]; else times"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Vnos pravnih oseb po davčni številki
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.CLIENT_VAT_SEARCH]; else times"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span class="font-bold text-lg" i18n>
            Dokumenti
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Kupoprodajne pogodbe
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.DOCUMENTS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Primopredajni zapisnik ob nakupu
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.DOCUMENTS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Pooblastilo za upravne postopke v zvezi z vozilom
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.DOCUMENTS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Primopredajni zapisnik pri prodaji
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.DOCUMENTS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Potrdilo o prejemu are
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.DOCUMENTS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Pogodba o komisijski prodaji za fizične osebe
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.DOCUMENTS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Dovoljenje za preizkušnjo vožnjo
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.DOCUMENTS]; else times"></i>
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span class="font-bold text-lg" i18n>
            Administracija
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Predračuni
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Računi
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
        <ng-container *ngIf="productPackage.limitInvoices">
          <span class="ml-2" i18n>
              ({{productPackage.limitInvoices}} izdanih računov na mesec)
          </span>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Vse potrebne klavzule za prodajo vozil
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Davčna blagajna
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.FEATURE_FLAG_FISCALIZATION_V2]; else times"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Avansi
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Dobropisi
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Plačila
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Blagajniški prejemek
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Izvoz izdanih računov za računovodstvo
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.ADMINISTRATION_EXPORT]; else times"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span class="font-bold text-lg" i18n>
            Partnerske integracije
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span i18n>
Digitalno izdajanje jamstev
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span i18n>
Digitalno izdajanje vlog za leasing
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.NLBUX]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span i18n>
Digitalno izdajanje vlog za kreditiranje
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.LEANPAY]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span i18n>
Podpisna komponenta
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span class="font-bold text-lg" i18n>
            Pregled poslovanja
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Tabelarični pregled poslovanja
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.FEATURE_FLAG_ANALYTICS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Grafični pregled poslovanja
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.FEATURE_FLAG_ANALYTICS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Izvoz podatkov
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.FEATURE_FLAG_ANALYTICS]; else times"></i>
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span class="font-bold text-lg" i18n>
Uporabniške nastavitve
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
      </div>
    </div>
  </div>

  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span i18n>
            Število uporabnikov
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <span class="text-medium text-lg">
          {{productPackage.limitUsers ?? '∞'}}
        </span>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span i18n>
Mobilni dostop
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span i18n>
Določanje vlog in pravic uporabnikov
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.ROLES_AND_PERMISSIONS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span i18n>
Dodatne poslovne enote
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.FEATURE_FLAG_BUSINESS_UNITS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="w-full text-left">
        <span i18n>
Direkten dostop za računovodje
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle" *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"
           *ngIf="productPackage.featureFlags[AccountFeaturesEnum.ACCOUNTANT_ACCESS]; else times"></i>
      </div>
    </div>
  </div>
  <div class="grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
            Uvajanje in podpora
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <div>
          <i class="fas fa-envelope text-primary mr-2"></i><span i18n>E-mail</span>
        </div>
        <div *ngIf="productPackage.featureFlags[AccountFeaturesEnum.IMPLEMENTATION_AND_SUPPORT_BY_PHONE]">
          <i class="fas fa-phone text-primary mx-2"></i><span i18n>Telefon</span>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span class="font-bold text-lg" i18n>
Varnost in nadgradnje
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Varnostno arhiviranje podatkov v oblaku AWS
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Brezplačni prostor za shranjevanje
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
      *ngFor="let size of getSizesForProductPackages()">
      <div class="flex justify-center items-center h-full">
        <span>{{ size }}</span>
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Nenehne nadgradnje in izboljšave platfrome
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>

  <div class="bg-grayHover grid grid-cols divide-x-1 divide-grayLight divide-solid justify-between h-full"
       [ngClass]="'grid-cols-' + (productPackages.length + 1)">
    <div class="px-3 py-1 h-full align-middle">
      <div class="text-left">
        <span i18n>
Takojšne zakonodajne spremembe
        </span>
      </div>
    </div>
    <div class="px-3 py-1 h-full align-middle"
         *ngFor="let productPackage of productPackages">
      <div class="flex justify-center items-center h-full">
        <i class="fas fa-check text-primary"></i>
      </div>
    </div>
  </div>
</div>

<ng-template #times>
  <i class="fas fa-times text-red"></i>
</ng-template>
