<div class="flex flex-row items-center justify-between">
  <div class="flex items-center sm:flex-nowrap flex-row group" [ngClass]="{'flex-wrap' : dialogSelected}">
    <a [routerLink]="['/admin/vehicles', vehicle.vehicleUuid]" class="hover:no-underline focus:no-underline self-start"
       *ngIf="!disableLink">
      <ng-container *ngTemplateOutlet="vehicleImage"></ng-container>
    </a>

    <div *ngIf="disableLink" class="self-start" [ngClass]="{'sm:mb-0' : dialogSelected}">
      <ng-container *ngTemplateOutlet="vehicleImage"></ng-container>
    </div>

    <div *ngIf="vehicle">
      <ng-container *ngTemplateOutlet="vehicleDetails"></ng-container>
    </div>
  </div>
  <div>
    <ng-container *ngIf="!hideStockTurnover">
      <ng-container
              *ngIf="vehicle?.stockTurnover?.vehicleSoldAt === null && vehicle?.stockTurnover?.vehicleBoughtAt !== null; else withoutBoughtDate">
        <div class="card border-0 flex items-center justify-center" style="width:32px; height:32px;"
             [ngClass]="{
                'bg-orange' : (vehicle.stockTurnover.vehicleBoughtAt | datediff:'days') >= 120 && (vehicle.stockTurnover.vehicleBoughtAt | datediff:'days') <= 180,
                'bg-red' : (vehicle.stockTurnover.vehicleBoughtAt | datediff:'days') >= 180
              }"
             matTooltipClass="bg-black px-4 py-2 text-white text-sm"
             matTooltip="To vozilo imate že dolgo na zalogi!"
             i18n-matTooltip="Vehicle stock turnover"
             *ngIf="(vehicle.stockTurnover.vehicleBoughtAt | datediff:'days') >= 120"
        ><i class="fa fa-exclamation-triangle text-white"></i>
        </div>
      </ng-container>
      <ng-template #withoutBoughtDate>
        <div class="card border-0 flex items-center justify-center" style="width:32px; height:32px;"
             [ngClass]="{
              'bg-orange' : (vehicle.stockTurnover.vehicleCreatedAt | datediff:'days') >= 120 && (vehicle.stockTurnover.vehicleCreatedAt | datediff:'days') <= 180,
              'bg-red' : (vehicle.stockTurnover.vehicleCreatedAt | datediff:'days') >= 180
              }"
             *ngIf="!vehicle.stockTurnover?.vehicleSoldAt && (vehicle.stockTurnover?.vehicleCreatedAt | datediff:'days') >= 120"
             matTooltipClass="bg-black px-4 py-2 text-white text-sm"
             matTooltip="To vozilo imate že dolgo na zalogi!"
             i18n-matTooltip="Vehicle stock turnover"
        >
          <i class="fa fa-exclamation-triangle text-white" matTooltipClass="bg-black px-4 py-2 text-white text-sm"
             matTooltip="Barva vozila" i18n-matTooltip="Vehicle stock turnover"></i>
        </div>
      </ng-template>
    </ng-container>

  </div>
</div>

<ng-template #vehicleImage>
  <div class="mr-3">
    <div *ngIf="vehicle?.featuredImage; else imagePlaceholder" style="width: 70px">
      <ng-container *ngIf="vehicle.archivedAt; else normalImage">
        <img class="object-cover w-full rounded"
             [src]="vehicle.featuredImage.files?.[0]?.conversions?.large ?? vehicle.featuredImage.files?.[0]?.url"
             alt="Vehicle photo"
             loading="lazy"
             style="-webkit-filter: grayscale(100%);filter: grayscale(100%);border-radius:4px;"
        >
      </ng-container>
      <ng-template #normalImage>
        <img class="object-cover w-full rounded"
             [src]="vehicle.featuredImage.files?.[0]?.conversions?.large ?? vehicle.featuredImage.files?.[0]?.url"
             alt="Vehicle photo"
             loading="lazy"
             style="border-radius:4px;"
        >
      </ng-template>
    </div>

    <ng-template #imagePlaceholder>
      <div style="height: 64px; width: 64px">
        <ng-container *ngIf="vehicle?.vehicle && !showPlaceholder">
          <img src="assets/images/brands/img_{{ vehicleBrandSlug }}.png" loading="lazy"
               (error)="forcePlaceholder()" alt="{{ vehicle.vehicle.brand }}">
        </ng-container>
        <ng-container *ngIf="!vehicle || !vehicle.vehicle || showPlaceholder">
          <app-image-placeholder></app-image-placeholder>
        </ng-container>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #vehicleDetails>
  <a [routerLink]="['/admin/vehicles', vehicle.vehicleUuid]" class="hover:no-underline focus:no-underline"
     [target]="openInBlank? '_blank' : '_self'"
     *ngIf="!disableLink">
    <div
            class="text-greenDarkOlive text-sm font-semibold leading-normal whitespace-normal group-hover:text-primary">{{ vehicle.vehicle?.name ?? '-' }}
    </div>
  </a>

  <div class="text-greenDarkOlive text-sm font-semibold leading-normal whitespace-normal"
       *ngIf="disableLink">{{ vehicle.vehicle?.name ?? '-' }}
  </div>

  <div class="text-sm text-grayDark leading-normal whitespace-normal"
       style="line-height: 1;">{{ vehicleDescription }}
  </div>

  <div class="text-sm text-grayDark leading-normal whitespace-normal font-bold"
       *ngIf="showAdvertisedPrice && vehicle?.stockTurnover?.advertisedPrice && vehicle?.stockTurnover?.advertisedPrice"
       style="line-height: 1;">
    {{ vehicle.stockTurnover.advertisedPrice | multiCurrency: accountConfig$ | async }}
  </div>

  <div class="">
    <ng-container *ngIf="vehicle.archivedAt === null; else archived"></ng-container>
  </div>

  <div class="">
    <ng-container *ngIf="showInvoice && vehicle.saleInvoice && invoiceType === 'sale'">
      <div class="leading-normal flex flex-col text-sm text-grayDark"
           *ngIf="vehicle.saleInvoice.documentNumber && vehicle.saleInvoice.documentDate">
        <span><b>Št. računa:</b> {{ vehicle.saleInvoice.documentNumber }}</span>
        <span><b>Datum računa:</b> {{ vehicle.saleInvoice.documentDate | date:'dd.LL.yyyy' }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="vehicle.saleInvoice && invoiceType === 'sale'">
      <div>
        <div class="bg-redWashed uppercase font-bold text-xs text-red px-2 py-1 rounded leading-none table"
             data-test="vehicle-table-cell-status-sold">
          <span i18n *ngIf="!vehicleBeforeSoldStatus">Prodano</span>
          <div *ngIf="vehicleBeforeSoldStatus">
            <span i18n>Prodano / V statusu: </span>
            {{ vehicleBeforeSoldStatus?.name }}
          </div>
        </div>

        <div class="mt-2 text-red text-sm flex flex-row" *ngIf="!vehicle.archivedAt && !hideWarning">
          <i class="fas fa-exclamation-triangle mr-1" style="margin-top: 2px"></i>
          <div class="text-xs leading-normal" i18n>To vozilo je na zalogi kljub temu da je prodano.
            <a class="cursor-pointer" *ngIf="!hideActions" (click)="openDialogToArchive()">Prestavi v arhiv</a></div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isSaleAndLeaseBack">
      <div>
        <div class="bg-blueWashed uppercase font-bold text-xs text-blue px-2 py-1 rounded leading-none table">
          <span i18n>Sale & Lease Back</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="vehicle.commission_sale">
      <div>
        <div class="bg-orangeWashed uppercase font-bold text-xs text-orange px-2 py-1 rounded leading-none table">
          <span i18n>Komisijska prodaja</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div>
    <div class="flex" *ngIf="identificationShow">
      <app-vehicle-identification-table-cell [vehicle]="vehicle"
                                             [directionRow]="true"></app-vehicle-identification-table-cell>
    </div>
  </div>
</ng-template>

<ng-template #archived>
  <div class="" *ngIf="vehicle.archivedAt && !vehicle.saleInvoice">
    <div class="bg-yellowWashed text-yellow uppercase font-bold text-xs px-2 py-1 rounded leading-none table mt-1">
      <span i18n>Arhivirano</span>
    </div>
  </div>
</ng-template>
