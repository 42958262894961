<div class="w-full relative h-full">
  <div class="flex flex-col h-full overflow-y-auto">
    <div class="flex flex-row">
      <div class="flex flex-col w-full">
        <button *ngIf="isDemo" class="btn btn-sm btn-primary-outline w-full mt-4" (click)="openSwitchToFull()">
          <span i18n class="font-semibold">Demo račun</span>
        </button>
        <div class="px-4 py-4">
          <h3 i18n="Left sidebar menu item title" class="overline text-gray">Področja</h3>
        </div>

        <a [routerLink]="['/admin/dashboard']" i18n-title title="Namizje"
           *appIfPermission="[PermissionEnum.MENU_GLOBAL_DASHBOARD_VIEW]"
           routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
           [routerLinkActiveOptions]="{exact: true}"
           class="vehicles rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
          <div class="px-4 text-lg w-12">
            <i class="fas fa-th-large opacity-75"></i>
          </div>
          <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
                style="top: 2px">Namizje</span>
        </a>

        <a [routerLink]="['/admin/vehicles']" i18n-title title="Vozila"
           *appIfPermission="[PermissionEnum.MENU_GLOBAL_VEHICLES_VIEW]"
           routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
           class="vehicles rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
          <div class="px-4 text-lg w-12">
            <i class="fas fa-car opacity-75"></i>
          </div>
          <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
                style="top: 2px">Vozila</span>
        </a>
        <a [routerLink]="['/admin/clients']" i18n-title title="Imenik"
           *appIfPermission="[PermissionEnum.MENU_GLOBAL_CLIENTS_VIEW]"
           routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
           class="clients rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
          <div class="px-4 text-lg w-12">
            <i class="fas fa-users opacity-75"></i>
          </div>
          <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
                style="top: 2px">Imenik</span>
        </a>

        <a [routerLink]="['/admin/documents']" i18n-title title="Dokumenti"
           *appIfPermission="[PermissionEnum.MENU_GLOBAL_DOCUMENTS_VIEW]"
           routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
           class="documents rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
          <div class="px-4 text-lg w-12">
            <i class="fas fa-file-contract opacity-75"></i>
          </div>
          <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
                style="top: 2px">Dokumenti</span>
        </a>

        <a [routerLink]="['/admin/assets']" i18n-title title="Blago & Storitve"
          *appIfPermission="[PermissionEnum.MENU_GLOBAL_ASSETS_VIEW]"
          routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
          class="assets rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
          <div class="px-4 text-lg w-12">
            <i class="fas fa-wrench opacity-75"></i>
          </div>
          <span class="font-bold pl-1 pr-3 relative text-sm" style="top: 2px">
            <span i18n="Left sidebar menu item" class="mr-2">Blago & Storitve</span>
          </span>
        </a>

        <button (click)="toggleAdministrationSideMenu()" i18n-title
                title="Administracija"
                data-test="menu-administration-button"
                *appIfPermission="[PermissionEnum.MENU_GLOBAL_ADMINISTRATION_VIEW]"
                [ngClass]="{'active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover': isAdministrationUrl()}"
                class="administration cursor-pointer rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut justify-between">
          <div class="grow-0 flex flex-row">
            <div class="px-4 text-lg w-12">
              <i class="fas fa-file-invoice opacity-75"></i>
            </div>
            <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
                  style="top: 2px">Administracija</span>
          </div>

          <div class="px-2 grow-0">
            <div *ngIf="!showAdminSideMenu">
              <i class="fas fa-chevron-right"></i>
            </div>

            <div *ngIf="showAdminSideMenu">
              <i class="fas fa-chevron-left"></i>
            </div>
          </div>
        </button>


        <ng-container *appIfAccountFeatureFlag="AccountFlagEnum.FEATURE_FLAG_MARKET_STUDY">
          <a [routerLink]="['/admin/market-study']" i18n-title title=""
             *appIfPermission="[PermissionEnum.MENU_GLOBAL_MARKET_STUDY_VIEW]"
             routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
             class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
            <div class="px-4 text-lg w-12">
              <i class="fas fa-chart-line opacity-75"></i>
            </div>
            <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
                  style="top: 2px">Valuacija</span>
          </a>
        </ng-container>

        <ng-container *appIfAccountFeatureFlag="AccountFlagEnum.IS_AVAILABLE_MOTIVE_SERVICE">

          <ng-container *appIfAccountFeatureFlag="AccountFlagEnum.FEATURE_FLAG_WARRANTIES_V2">
            <a [routerLink]="['/admin/warrantiesv2/all']" i18n-title title=""
               *appIfPermission="[PermissionEnum.MENU_GLOBAL_WARRANTIES_VIEW]"
               routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
               class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
              <div class="px-4 text-lg w-12">
                <i class="fas fa-file-medical opacity-75"></i>
              </div>
              <span i18n class="font-bold pl-1 pr-3 relative text-sm" style="top: 2px">Jamstva</span>
            </a>
          </ng-container>

          <ng-container *appIfAccountFeatureFlag="[]; hideFor: [AccountFlagEnum.FEATURE_FLAG_WARRANTIES_V2]">
            <a [routerLink]="['/admin/warranties/all']" i18n-title title="Warranties"
               *appIfPermission="[PermissionEnum.MENU_GLOBAL_WARRANTIES_VIEW]"
               data-test="menu-warranties-button"
               routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
               class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
              <div class="px-4 text-lg w-12">
                <i class="fas fa-file-medical opacity-75"></i>
              </div>
              <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
                    style="top: 2px">Jamstva</span>
            </a>
          </ng-container>
        </ng-container>


        <ng-container *appIfPermission="[PermissionEnum.MENU_GLOBAL_FINANCING_VIEW]">
          <button *appIfAccountFeatureFlag="[AccountFlagEnum.FEATURE_FLAG_FINANCING_V3]"
                  data-test="menu-financing-button"
                  class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut w-full"
                  [routerLink]="['/admin/leasings/financing/providers']"
                  [routerLinkActive]="'active text-greenDarkOlive'">
            <!--        (click)="toggleFinancingMenu()"-->

            <div class="px-4 text-lg w-12">
              <i class="fas fa-money-check-alt opacity-75"></i>
            </div>
            <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm grow text-left"
                  style="top: 2px">Financiranja</span>

            <!--  <div class="px-2 grow-0">-->
            <!--    <div *ngIf="!financingMenuOpened">-->
            <!--      <i class="fas fa-chevron-down"></i>-->
            <!--    </div>-->

            <!--    <div *ngIf="financingMenuOpened">-->
            <!--      <i class="fas fa-chevron-up"></i>-->
            <!--    </div>-->
            <!--  </div>-->

          </button>
          <!--<div class="ml-8" *ngIf="financingMenuOpened">-->
          <!--  <a [routerLink]="['/admin/leasings/financing/providers']"-->
          <!--     i18n-title title="Leasings"-->
          <!--     routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"-->
          <!--     [routerLinkActiveOptions]="{ exact: true }"-->
          <!--     class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">-->
          <!--    <span i18n="Left sidebar menu item" class="pl-1 pr-3 relative text-sm" style="top: 2px">Financiranja</span>-->
          <!--  </a>-->

          <!--  <a [routerLink]="['/admin/leasings/financing/providers/nlb']"-->
          <!--     i18n-title title="NLb Leasing"-->
          <!--     routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"-->
          <!--     class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">-->
          <!--    <span i18n="Left sidebar menu item" class="pl-1 pr-3 relative text-sm" style="top: 2px">NLB Lease & Go</span>-->
          <!--  </a>-->
          <!--</div>-->
        </ng-container>

        <ng-container *appIfPermission="[PermissionEnum.MENU_GLOBAL_BUSINESS_OVERVIEW_VIEW]">
          <button
                  data-test="menu-business-overview-button"
                  class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut w-full"
                  (click)="toggleBusinessOverviewMenu()"
                  [ngClass]="{'active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover': isBusinessOverviewUrl()}"
                  [routerLinkActive]="'active text-greenDarkOlive'">

            <div class="px-4 text-lg w-12">
              <i class="fas fa-file-invoice opacity-75"></i>
            </div>
            <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm grow text-left"
                  style="top: 2px">Pregled poslovanja</span>

            <div class="px-2 grow-0">
              <div *ngIf="!businessOverviewMenuOpened">
                <i class="fas fa-chevron-right"></i>
              </div>

              <div *ngIf="businessOverviewMenuOpened">
                <i class="fas fa-chevron-left"></i>
              </div>
            </div>

          </button>
        </ng-container>


        <ng-container *appIfAccountFeatureFlag="AccountFlagEnum.IS_AVAILABLE_TRADING_HUB">
          <ng-container *appIfPermission="[PermissionEnum.MENU_GLOBAL_TRADING_HUB_VIEW]">
            <a [routerLink]="['/admin/trading/dashboard']" i18n-title title="Trading Hub"
               routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
               [routerLinkActiveOptions]="{exact: true}"
               class="vehicles rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
              <div class="px-3 flex items-center">
                <img src="assets/images/icons/trading_hub_euro_icon.png" alt="Trading Hub Icon" class="h-5 w-6">
              </div>
              <span class="font-bold pl-1 pr-3 relative text-sm" style="top: 2px">
                <span class="mr-3" i18n >Trading Hub</span>
                <span class="bg-orange text-white text-xs px-2 py-1 rounded font-bold whitespace-normal inline-block" i18n >BETA</span>
              </span>
            </a>
          </ng-container>
        </ng-container>

      </div>
      <div class="bg-white ml-4 md:ml-3 h-full md:hidden">
        <div class="border-l border-grayLight h-full md:border-r" *ngIf="showAdminSideMenu"
             (click)="toggleAdministrationSideMenu()">
          <app-administration-sidebar></app-administration-sidebar>
        </div>
        <div class="border-l border-grayLight h-full md:border-r" *ngIf="businessOverviewMenuOpened"
             (click)="toggleBusinessOverviewMenu()">
          <div class="px-4 py-4">
            <h3 i18n="Left sidebar menu item title" class="overline text-gray">Pregled poslovanja</h3>

            <a [routerLink]="['/admin/business-overview/general-table-overview']" i18n-title title="Tabelaricni prikaz"
               *appIfPermission="[PermissionEnum.MENU_BUSINESS_OVERVIEW_TABLE_VIEW]"
               routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
               class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut"
               data-test="menu-business-overview-table-view-button"
            >
        <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
              style="top: 2px">Tabelarični prikaz</span>
            </a>

            <a [routerLink]="['/admin/business-overview/general-graphical-overview']" i18n-title title="Graficni prikaz"
               *appIfPermission="[PermissionEnum.MENU_BUSINESS_OVERVIEW_GRAPHS_VIEW]"
               routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
               class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut"
               data-test="menu-business-overview-graph-view-button"
            >
              <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm" style="top: 2px">Grafični prikaz</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full">
      <ng-container *appIfPermission="[PermissionEnum.MENU_TOOLS_VIEW]">
        <div class="px-4 py-4 mt-6 w-full">
          <h3 i18n="Left sidebar menu item title" class="overline text-gray">Orodja</h3>
        </div>
        <a [routerLink]="['/signature']" i18n-title title="Podpis"
           routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
           class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
          <div class="px-4 text-lg w-12">
            <i class="fas fa-signature opacity-75"></i>
          </div>
          <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
                style="top: 2px">Podpis</span>
        </a>
      </ng-container>


      <!-- <div class="px-4 py-4 mt-6">
        <h3 i18n="Left sidebar menu item title" class="overline text-gray">Pomoč</h3>
      </div> -->

      <!-- <app-help-contact-us></app-help-contact-us> -->
    </div>
  </div>

  <div class="absolute bg-white ml-4 md:ml-3 h-full hidden md:block md:h-screen"
       style="left:100%; top:2px; z-index: 999;">
    <div class="border-l border-grayLight h-full md:border-r" *ngIf="showAdminSideMenu"
         (click)="toggleAdministrationSideMenu()">
      <app-administration-sidebar></app-administration-sidebar>
    </div>
    <div class="border-l border-grayLight h-full md:border-r" *ngIf="businessOverviewMenuOpened"
         (click)="toggleBusinessOverviewMenu()">
      <div class="px-4 py-4">
        <h3 i18n="Left sidebar menu item title" class="overline text-gray">Pregled poslovanja</h3>
        <a [routerLink]="['/admin/business-overview/general-table-overview']" i18n-title title="Tabelaricni prikaz"
           [attr.data-test]="PermissionEnum.MENU_BUSINESS_OVERVIEW_TABLE_VIEW"
           routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
           class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
        <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm"
              style="top: 2px">Tabelarični prikaz</span>
        </a>

        <a [routerLink]="['/admin/business-overview/general-graphical-overview']" i18n-title title="Graficni prikaz"
           [attr.data-test]="PermissionEnum.MENU_BUSINESS_OVERVIEW_GRAPHS_VIEW"
           routerLinkActive="active bg-greenSidebarHover text-greenDarkOlive rounded hover:bg-greenSidebarHover"
           class="rounded py-3 flex flex-row text-grayDark hover:no-underline hover:bg-grayNavi hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut">
          <span i18n="Left sidebar menu item" class="font-bold pl-1 pr-3 relative text-sm" style="top: 2px">Grafični prikaz</span>
        </a>
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</div>

