export const environment = {
  name: 'production-app',
  production: true,
  staging: false,
  apiUrl: 'https://api.briefd.io',
  uploadsApiUrl: 'https://uploads.autobrief.io',
  extensionUrl: 'https://chromewebstore.google.com/detail/quickentry/nkhajogmaiobipcelekkhoinpddddefn',
  extensionId: 'nkhajogmaiobipcelekkhoinpddddefn',
  gtmID: 'GTM-KH5BQNX8',
  facebookTrackingID: '437843593839236',
  hotjarTrackingID: '1754688',
  navbarColor: '#2F6C5B',
  version: '<date-commit>',
  kwToHpRatio: 1.36,
  showDoberAvtoAuthHeaderInSettings: false,
  product_fruits: {
    code: 'kLMSgJNkefsfvedp',
  },
  ssr: {
    port: 4002,
    process: 'briefd-production'
  },
  features: {
    routes: {
      demo: false,
    }
  },
  doberAvtoAdBaseUrl: 'https://doberavto.si/oglas/',
  tradingHubBaseUrl: 'https://autobrief.odoo.com/'
};
