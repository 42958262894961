<div *ngIf="isLoading; else content">
  <div class="card bg-white">
    <div class="card-content">
      <div class="mb-3">
        <app-content-shimmer height="60px"></app-content-shimmer>
      </div>
      <div class="mb-3">
        <app-content-shimmer height="60px"></app-content-shimmer>
      </div>
    </div>
  </div>
</div>

<ng-template #content>
  <div class="card h-full bg-white relative"
       [ngClass]="{'border-green border-2': ((isActive && !activePackage.to) || productPackage.isPreferred), 'border-grayTableBorder': !isActive, 'border-orange border-2': isActive && activePackage.to && daysTillEndOfSubscription > 0 && !activePackage.isExpired}">
    <div class="bg-primary text-white text-center font-bold" *ngIf="isActive && !activePackage.to" i18n>AKTIVEN</div>
      <div class="bg-primary text-white text-center text-xs font-bold p-1 absolute rounded-full" style="top: -10px; right: -10px;" *ngIf="productPackage.isPreferred" i18n>NAJBOLJ POPULARNO</div>
    <div class="bg-orange text-white text-center font-bold"
         *ngIf="isActive && activePackage.to && daysTillEndOfSubscription > 0 && !activePackage.isExpired"><span i18n>AKTIVEN še</span>&nbsp;
      <ng-container [ngSwitch]="daysTillEndOfSubscription">
        <span *ngSwitchCase="1" i18n>{{ daysTillEndOfSubscription }} dan</span>
        <span *ngSwitchCase="2" i18n>{{ daysTillEndOfSubscription }} dneva</span>
        <span *ngSwitchDefault i18n>{{ daysTillEndOfSubscription }} dni</span>
      </ng-container>
    </div>
    <div class="card-content flex flex-row ">
      <div class="w-full h-full flex flex-col items-start">
        <div class="flex w-full text-lg">
          <div class="font-bold"><span class="uppercase">{{ productPackage.name }}</span>&nbsp;<span i18n>Paket</span>
          </div>
          <div class="grow flex justify-start md:justify-end items-end">
            <div class="font-bold text-primary text-right">
              {{ (subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY ? (++productPackage.priceMonthly) : (++productPackage.priceYearly / 12)) | multiCurrency:accountConfig$ | async }}
              <span *ngIf="((!productPackage.priceMonthly || productPackage.priceMonthly === 0) && subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY) || ((!productPackage.priceYearly || productPackage.priceYearly === 0) && subscriptionType === productPackageSubscriptionTypeEnum.YEARLY)"
                    i18n> BREZPLAČNO</span>
              <span *ngIf="productPackage.priceMonthly > 0 && subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY"
                    i18n> / mesec</span>
              <span *ngIf="productPackage.priceYearly > 0 && subscriptionType === productPackageSubscriptionTypeEnum.YEARLY"
                    i18n> / mesec*</span>
              <span *ngIf="productPackage.priceYearly > 0 && subscriptionType === productPackageSubscriptionTypeEnum.YEARLY"
                    class="text-xs text-gray block"
                    i18n>*prihranite 10% z letnim plačilom</span>

              <span *ngIf="((productPackage.priceMonthly && productPackage.priceMonthly > 0) && subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY) || ((productPackage.priceYearly && productPackage.priceYearly > 0) && subscriptionType === productPackageSubscriptionTypeEnum.YEARLY)"
                    class="text-xs text-gray block"
                    i18n>cena ne vsebuje DDV</span>
            </div>

          </div>
        </div>

        <div class="flex flex-row w-full">
          <div class="grow flex justify-start md:justify-end items-end"
               *ngIf="subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY ? productPackage.discountMonthly : productPackage.discountYearly">
            <div class="text-grayDark text-sm leading-normal">
                <span class="line-through">
                  {{ (subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY ? (++productPackage.priceMonthly + ++productPackage.discountMonthly) : ((++productPackage.priceYearly / 12) + (++productPackage.discountYearly / 12))) | multiCurrency:accountConfig$ | async }}
                  <span *ngIf="subscriptionType === productPackageSubscriptionTypeEnum.MONTHLY" i18n> / mesec</span>
                <span *ngIf="subscriptionType === productPackageSubscriptionTypeEnum.YEARLY"
                      i18n> / mesec</span></span>
            </div>
          </div>
        </div>

        <div class="flex w-full mt-4">
          <i class="text-primary fas fa-users"></i>
          <div class="text-sm ml-2 text-left font-bold">{{ productPackage.limitUsers }}
            <span *ngIf="productPackage.limitUsers === 1" i18n>uporabnik</span>
            <span *ngIf="productPackage.limitUsers === 2" i18n>uporabnika</span>
            <span *ngIf="productPackage.limitUsers >= 3 && productPackage.limitUsers <= 4" i18n>uporabniki</span>
            <span *ngIf="productPackage.limitUsers >= 5" i18n>uporabnikov</span>

          </div>
        </div>

        <hr class="h-px w-full mt-4 mb-4 bg-grayHover dark:bg-grayDark">

        <div class="w-full h-full bg-grayNavi rounded p-3">
          <button class="btn-sm btn-link text-xs p-0 text-primary hover:no-underline hover:text-greenDarkOlive focus:no-underline relative fadeInFadeOut"
                  (click)="toggleFeatures()">
            <span *ngIf="!toggle" i18n>Paket vključuje</span>
            <span *ngIf="toggle" i18n>Skrij podrobnosti paketa</span>
            <i class="ml-2 mt-1 fa"
               [ngClass]="{'fa-angle-down text-primary': !toggle, 'fa-angle-up text-gray': toggle}"></i>
          </button>
          
          <div class="w-full h-full bg-grayNavi rounded p-3" *ngIf="toggle">
            <app-registration-page-product-package-features [productPackages]="[productPackage]">
            </app-registration-page-product-package-features>
          </div>
        </div>

        <ng-container *ngIf="userProfile$ | async as userProfile">
          <div class="flex justify-between mt-3 w-full">
            <div>
              <button class="btn btn-primary px-3 py-1" *ngIf="!isActive || trialExpired"
                      [disabled]="!userProfile.isAdmin || (!activePackage.isFree && !activePackage.isTrial && !activePackage.isExpired && !activePackage.isPaid)"
                      (click)="upgradePackage(productPackage, subscriptionType)">
                <span i18n>Izberi paket</span>
                <app-tooltip class="ml-1" classes="text-primary"
                             *ngIf="(!activePackage.isFree && !activePackage.isTrial && !activePackage.isExpired && !activePackage.isPaid)"
                             content="Paketa ne morete izbrati ker imate odprte dolgove">
                </app-tooltip>
                <app-tooltip class="ml-1" classes="text-primary" *ngIf="(!userProfile.isAdmin)"
                             content="Samo administrator oz. glavni uporabnik lahko spreminja paket">
                </app-tooltip>
              </button>
              <button class="btn btn-danger-link px-3 py-1"
                      *ngIf="isActive && !trialExpired && !activePackage.to && productPackage.order > 1 && !activePackage.isTrial"
                      (click)="unsubscribePackage()">
                <span i18n>Prekliči naročnino</span>
              </button>
              <button class="btn btn-primary px-3 py-1"
                      *ngIf="isActive && !trialExpired && activePackage.to  && productPackage.order > 1 && !activePackage.isTrial"
                      (click)="reSubscribePackage()">
                <span i18n>Ponovno aktiviraj</span>
              </button>
              <button class="btn btn-primary-link px-3 py-1"
                      *ngIf="isActive && !trialExpired && !activePackage.to && productPackage.order > 1 && !activePackage.isTrial && activePackage.paymentUrl"
                      (click)="openSubscriptionPaymentSettings()">
                <span i18n>Nastavitve Naročnine</span>
              </button>
            </div>
          </div>
          <div *ngIf="!userProfile.isAdmin">
            <span class="text-sm text-gray font-bold" i18n>Samo lastnik računa lahko upravlja z paketi</span>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</ng-template>
